.root-bg {
	background-color: var(--vkws_landing--access--bg);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 28px;
	padding-bottom: 50px;
	position: relative;
}

.main-title {
	margin: 0;
	padding-bottom: 35px;
	color: var(--vkws_landing--access-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.card {
	overflow: hidden;
	background-color: var(--vkws_landing--access-card--bg);
	width: 300px;
	height: 534px;
	border-radius: 14px;
	padding: 30px 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
}

.desc {
	color: var(--vkws_landings--color_body);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.download-button {
	box-sizing: content-box;
	cursor: pointer;
	border-radius: 6px;
	background: var(--vkws_landing--access-card-button--bg);
	display: flex;
	width: 120px;
	height: 50px;
	justify-content: center;
	align-items: center;
	color: var(--vkws_landings--color_body);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.download-button:hover {
	background: var(--vkws_landings--header_button_move--bg--hover);
}

.download-button:active {
	background: var(--vkws_landings--header_button_move--bg--active);
}

.button-wrapper {
	margin-top: 40px;
	margin-bottom: auto;
}

.image {
	display: flex;
	width: 290px;
	position: absolute;
	bottom: 0;
	right: -86px;
	z-index: var(--vkws_landings--image_background--z-index);
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 40px;
		padding-bottom: 60px;
		width: 884px;
	}

	.main-title {
		padding-bottom: 40px;
		font-size: 32px;
		line-height: 120%;
	}

	.card {
		width: 884px;
		height: 338px;
		padding: 28px;
		border-radius: 14px
	}

	.download-button {
		width: 110px;
		height: 42px;
		border-radius: 7px;
	}

	.button-wrapper {
		margin-top: 0;
		margin-bottom: 0;
	}

	.desc {
		font-size: 26px;
		line-height: 140%;
		max-width: 380px;
	}

	.image {
		width: 306px;
		right: 0;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 48px;
		padding-bottom: 78px;
		width: 1240px;
	}

	.main-title {
		font-size: 54px;
		padding-bottom: 24px;
	}

	.card {
		border-radius: 20px;
		width: 1240px;
		height: 474px;
		padding: 40px;
	}

	.download-button {
		width: 156px;
		height: 58px;
		border-radius: 10px;
		font-size: 22px;
	}

	.desc {
		font-size: 36px;
		max-width: 531px;
	}

	.image {
		width: 432px;
	}
}
