.root {
	display: flex;
	flex-direction: column;
	min-width: 320px;
	height: 100vh;
}

.header-fixed {
	scroll-padding-top: calc(var(--vkws_landings--portal_header--height) + 89px);
}

.content {
	background-color: var(--vkws_landings--color--black);
}
