.root-bg {
	background-color: var(--vkws_landings--bg_body);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 32px;
	padding-bottom: 20px;
	position: relative;
}

.main-title {
	margin: 0;
	padding-bottom: 20px;
	color: var(--vkws_landing--advanced-functionality-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.link {
	color: var(--vkws_landing--advanced-functionality-card-title--color);
	text-decoration-line: underline;
	cursor: pointer;
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card {
	height: 300px;
	font-style: normal;
	padding: 20px;
	border-radius: 14px;
	background: var(--vkws_landing--advanced-functionality-card--bg);
}

.card-head {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.title {
	color: var(--vkws_landing--advanced-functionality-card-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.desc {
	color: var(--vkws_landing--advanced-functionality-card-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 13px;
	font-weight: 400;
	line-height: 140%;
	max-width: 250px;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 30px;
		padding-bottom: 30px;
		width: 884px;
	}

	.main-title {
		padding-bottom: 20px;
		font-size: 32px;
		line-height: 120%;
		max-width: 600px;
	}

	.cards {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;
	}

	.card {
		height: 310px;
		padding: 30px;
		border-radius: 14px;
	}

	.title {
		font-size: 24px;
	}

	.desc {
		font-size: 15px;
		max-width: 350px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 40px;
		padding-bottom: 70px;
		width: 1240px;
	}

	.main-title {
		font-size: 54px;
		padding-bottom: 24px;
		max-width: 1150px;
	}

	.card {
		height: 433px;
		padding: 32px;
		border-radius: 12px;
	}

	.title {
		font-size: 36px;
	}

	.desc {
		font-size: 22px;
		max-width: 510px;
	}
}
