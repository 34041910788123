.wrapper {
	background: var(--vkws_landings--color--black);
}

.root {
	padding-top: 60px;
	display: grid;
	grid-template-columns: 1fr;
}

.grid {
	display: grid;
	gap: 8px;
}

.title {
	font-size: 20px;
	font-weight: 500;
	line-height: 130%;
	margin: 0;
	color: var(--vkws_landings--color--white);
	z-index: 1;
}

.card {
	border-radius: 10px;
	padding: 20px;
	background: var(--vkws_landing--card--bg);
	overflow: hidden;
}

.bigCard {
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 186px;
}

.imageWrapper {
	position: absolute;
	bottom: -50px;
	right: -20px;
}

.vkTechImg {
	background: var(--vkws_landing--card--bg);
}

.cardWithIcon {
	align-items: center;
	display: grid;
	gap: 20px;
	grid-template-columns: min-content auto;
	grid-template-rows: auto;
}

.iconWrapper {
	background: #111111;
	width: 48px;
	height: 48px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	width: 28px;
	height: 28px;
}

.cardText {
	font-size: 15px;
	line-height: 21px;
	margin: 0;
	color: var(--vkws_landings--color--white);
}

@media screen and (min-width: 1024px) {
	.root {
		padding-top: 58px;
	}

	.grid {
		grid-template-columns: 325px repeat(3, 1fr);
	}

	.card {
		border-radius: 20px;
	}

	.title {
		line-height: 110%;
	}

	.bigCard {
		height: 100%;
		min-height: auto;
		justify-content: space-between;
		align-items: flex-start;
	}

	.imageWrapper {
		bottom: -50px;
		right: -75px;
	}

	.cardWithIcon {
		grid-template-columns: auto;
		grid-template-rows: min-content max-content;
	}

	.cardText {
		font-size: 13px;
		line-height: 18px;
	}

	.iconWrapper {
		width: 46px;
		height: 46px;
		border-radius: 14px;
	}

	.icon {
		width: 21px;
		height: 21px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-top: 80px;
	}

	.grid {
		grid-template-columns: 459px repeat(3, 1fr);
	}

	.title {
		font-size: 23px;
		line-height: 25px;
	}

	.bigCard {
		padding: 36px;
	}

	.imageWrapper {
		right: -100px;
		bottom: -65px;
	}

	.cardText {
		font-size: 17px;
		line-height: 24px;
	}

	.iconWrapper {
		width: 60px;
		height: 60px;
		border-radius: 18px;
	}

	.icon {
		width: 27px;
		height: 27px;
	}
}
