.root-bg {
	background-color: var(--vkws_landings--bg_body);
	position: relative;
	overflow: hidden;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 10px;
	padding-bottom: 20px;
	position: relative;
}

.main-title {
	margin: 0;
	padding-bottom: 20px;
	font-weight: 500;
	text-align: left;
	color: var(--vkws_landing--promo-title--color);
	z-index: 0;
	font-size: 28px;
	line-height: 34px;
	max-width: 300px;
}

.main-sub-title {
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 20px;
	text-align: left;
	color: var(--vkws_landing--promo-desc--color);
	font-size: 16px;
	line-height: var(--vkws_landings--font_accent_text--line_height--mobile);
}

.button {
	width: 100%;
	font-size: 16px;
	line-height: normal;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 50px;
	font-weight: 500;
	border-radius: 8px;
}

.section-wrapper-bg {
	position: relative;
	margin-top: 10px;
	margin-bottom: 34px;
	background-image: url('../assets/promo-illustration-mobile.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 342px;
	z-index: var(--vkws_landings--image_above_filter--z-index);
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 120px;
		padding-bottom: 40px;
		width: 884px;
	}

	.button {
		width: max-content;
		padding: 10px 25px;
		height: 40px;
		border-radius: 6px;
	}

	.main-title {
		padding-bottom: 18px;
		font-size: 40px;
		line-height: 110%;
		max-width: 542px;
	}

	.main-sub-title {
		max-width: 429px;
		padding-bottom: 18px;
		font-size: 16px;
		line-height: 24px;
	}

	.section-wrapper-bg {
		margin-top: 0;
		background-image: url('../assets/promo-illustration.webp');
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(20%, -40px);
		width: 468px;
		height: 538px;
		background-position: center;
		background-position-x: -265px;
		background-size: cover;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 78px;
		padding-bottom: 58px;
		width: 1240px;
	}

	.main-title {
		font-size: 64px;
		padding-bottom: 24px;
		max-width: 733px;
	}

	.main-sub-title {
		max-width: 674px;
		padding-bottom: 24px;
		font-size: var(--vkws_landings--font_accent_text--font_size--desktop);
		line-height: var(--vkws_landings--font_accent_text--line_height--desktop);
	}

	.button {
		font-size: 22px;
		padding: 15px 36px;
		height: 58px;
		border-radius: 10px;
	}

	.section-wrapper-bg {
		position: absolute;
		top: 10px;
		right: 0;
		width: 958px;
		height: 538px;
		background-size: cover;
		background-position: center;
		transform: translate(15%, 0);
	}
}
