.root-bg {
	background-color: var(--vkws_landings--bg_body);
}

.section {
	flex-direction: column;
	padding-top: 52px;
	padding-bottom: 82px;
}

.title {

}

.question {
	font-size: 18px;
	line-height: 1.25em;
}

.answer {
	font-size: 12px;
	line-height: 1.3em;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.title {
		margin-bottom: 40px;
	}

	.question-root {
		padding: 12px 0;
	}

	.question {
		font-size: 24px;
		line-height: 1.1em;
	}

	.answer {
		font-size: 15px;
		line-height: 1.4em;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 68px;
		padding-bottom: 50px;
	}

	.question-root {
		padding: 26px 0;
	}

	.question {
		font-size: 36px;
		line-height: 1.2em;
	}

	.answer {
		font-size: 24px;
	}
}
