.root {
	border-radius: 14px;
	padding: 25px 20px;
	background-color: var(--vkws_landing--secure-card--bg);
	overflow: hidden;
	height: 248px;
	width: 100%;
	position: relative;
}

.text {
	z-index: var(--vkws_landings--image_above_filter--z-index);
}

.img-wrapper {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: var(--vkws_landings--image_background--z-index);
}

.title {
	margin: 0 0 24px;
	color: var(--vkws_landings--color_body);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.desc {
	margin: 0;
	color: var(--vkws_landing--secure-card-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	max-width: 282px;
}

.img-one {
	width: calc(273px * 0.67);
}

.img-two {
	width: calc(406px * 0.67);
}

.img-three {
	width: calc(213px * 0.67);
}

.img-four {
	width: calc(257px * 0.67);
}

@media screen and (min-width: 1024px) {
	.root {
		width: 33.3%;
		height: 248px;
		padding: 28px 18px;
	}

	.title {
		margin-bottom: 28px;
	}

	.desc {
		max-width: 236px;
	}

	.img-one {
		width: calc(273px * 0.6);
	}

	.img-two {
		width: calc(406px * 0.6);
	}

	.img-three {
		width: calc(213px * 0.6);
	}

	.img-four {
		width: calc(257px * 0.6);
	}
}

@media screen and (min-width: 1440px) {
	.root {
		width: 33.3%;
		height: 348px;
		border-radius: 20px;
		padding: 36px 26px;
	}

	.title {
		margin-bottom: 39px;
		font-size: 28px;
	}

	.desc {
		font-size: 18px;
		max-width: 316px;
	}

	.img-one {
		width: calc(273px * 0.8);
	}

	.img-two {
		width: calc(406px * 0.8);
	}

	.img-three {
		width: calc(213px * 0.8);
	}

	.img-four {
		width: calc(257px * 0.8);
	}
}
