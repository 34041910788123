.root-bg {
	background-color: var(--vkws_landings--bg_body);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 52px;
	padding-bottom: 60px;
	position: relative;
}

.main-title {
	margin: 0;
	padding-bottom: 20px;
	color: var(--vkws_landing--variant-card-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card {
	overflow: hidden;
	background-color: var(--vkws_landing--variant-card--bg);
	width: 100%;
	height: auto;
	border-radius: 14px;
	padding: 30px 20px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.card-head {
	margin-bottom: 28px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.title {
	color: var(--vkws_landings--color--white);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	padding: 16px;
	background: var(--vkws_landing--services-card-icon--bg);
}

.icon svg {
	width: 28px;
	height: 28px;
}

.desc {
	color: var(--vkws_landing--variant-card-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.button-wrapper {
	margin-top: 27px;
	margin-bottom: 0;
}

.button {
	height: 50px;
	width: 100%;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
}

.white-button {
	background-color: var(--vkws_landings--color--white);
	color: var(--vkws_landings--color--black);
}

.white-button:hover, .white-button:focus, .white-button:active {
	background-color: var(--vkws_landing--variant-card-button--bg-hover);
	color: var(--vkws_landings--color--black);
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 60px;
		padding-bottom: 60px;
		width: 884px;
	}

	.main-title {
		padding-bottom: 60px;
		font-size: 32px;
		line-height: 120%;
	}

	.cards {
		flex-direction: row;
		gap: 10px;
	}

	.card {
		width: 50%;
		height: 500px;
		padding: 26px 30px;
		border-radius: 14px
	}

	.card-head {
		margin-bottom: 28px;
		gap: 28px;
	}

	.button-wrapper {
		margin-top: auto;
	}

	.title {
		font-size: 24px;
	}

	.desc {
		font-size: 18px;
		gap: 26px;
	}

	.button {
		height: 40px;
		width: 100%;
		border-radius: 6px;
		font-size: 16px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 80px;
		padding-bottom: 100px;
		width: 1240px;
	}

	.main-title {
		font-size: 54px;
		padding-bottom: 80px;
	}

	.cards {
		gap: 12px;
	}

	.card {
		border-radius: 20px;
		width: 50%;
		height: 752px;
		padding: 36px 26px;
	}

	.card-head {
		margin-bottom: 60px;
		gap: 38px;
	}

	.title {
		font-size: 36px;
	}

	.desc {
		font-size: 28px;
		gap: 36px;
		font-weight: 500;
		line-height: 130%;
	}

	.button {
		height: 58px;
		width: 100%;
		border-radius: 10px;
		font-size: 22px;
	}
}
