.root-bg {
	background-color: var(--vkws_landings--bg_body);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
}

.accent {
	color: var(--vkws_landings--color--turquoise);
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card {
	height: 210px;
	font-style: normal;
	padding: 20px;
	border-radius: 14px;
	background: var(--vkws_landing--services-card--bg);
}

.card-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.title {
	color: var(--vkws_landing--services-card-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 20px;
	font-weight: 500;
	line-height: 130%;
}

.desc {
	color: var(--vkws_landing--services-card-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 13px;
	font-weight: 400;
	line-height: 140%;
}

.icon {
	display: flex;
	width: 42px;
	height: 42px;
	padding: 9px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	background: var(--vkws_landing--services-card-icon--bg);
}

.icon svg {
	width: 25px;
	height: 25px;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 30px;
		padding-bottom: 30px;
		width: 884px;
	}

	.cards {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 9px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 70px;
		padding-bottom: 40px;
		width: 1240px;
	}

	.card {
		height: 294px;
		padding: 36px 26px;
		border-radius: 20px;
	}

	.card-head {
		align-items: start;
	}

	.title {
		font-size: 28px;
	}

	.desc {
		font-size: 18px;
	}

	.icon {
		width: 60px;
		height: 60px;
		border-radius: 12px;
	}

	.icon svg {
		width: 35px;
		height: 35px;
	}
}
