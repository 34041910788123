.root-bg {
	background-color: var(--vkws_landings--bg_body);
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	padding-top: 32px;
	padding-bottom: 50px;
}

.title {
	margin: 0 0 20px;
	color: var(--vkws_landings--color_body);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.list {
	margin: 0;
	padding: 0;
	gap: 9px;
	display: flex;
	flex-direction: column;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 30px;
		padding-bottom: 61px;
		width: 884px;
	}

	.title {
		font-size: 32px;
		max-width: 616px;
		margin-bottom: 20px;
	}

	.list {
		gap: 9px;
		flex-direction: row;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 60px;
		padding-bottom: 100px;
		width: 1240px;
	}

	.title {
		font-size: 54px;
		max-width: 1000px;
		margin-bottom: 60px;
	}

	.list {
		gap: 24px;
	}
}
