.css-local-variables {
	--vkws_landings--color_body: #FFF;
	--vkws_landings--bg_body: #000;
	--vkws_landing--promo-title--color: #FFF;
	--vkws_landing--promo-desc--color: #C2C2C2;
	--vkws_landing--main-text--color: var(--vkws_landings--color--white);
	--vkws_landing--accent-text--color: #B2B2B2;
	--vkws_landing--request--bg: #1D1D1F;
	--vkws_landing--request-title--color: #fff;
	--vkws_landing--header-auth-button--bg: #0077ff;
	--vkws_landing--header-auth-button--bg_hover: #0077ff;
	--vkws_landing--card--bg: #1D1D1F;

	--vkws_landing--services-card-title--color: var(--vkws_landings--color_body);
	--vkws_landing--services-card-desc--color: #BBBBBC;
	--vkws_landing--services-card-icon--bg: #121213;
	--vkws_landing--services-card--bg: #1D1D1F;

	--vkws_landing--advanced-functionality-title--color: #F1F1F1;
	--vkws_landing--advanced-functionality-card-title--color: #07F;
	--vkws_landing--advanced-functionality-card-desc--color: #BBBBBC;
	--vkws_landing--advanced-functionality-card--bg: #1D1D1F;

	--vkws_landing--secure-card--bg: #1D1D1F;
	--vkws_landing--secure-card-desc--color: #BBBBBC;

	--vkws_landing--access--bg: #1D1D1F;
	--vkws_landing--access-card--bg: #090909;
	--vkws_landing--access-card-button--bg: #232323;
	--vkws_landing--access-title--color: #F1F1F1;

	--vkws_landing--variant-card--bg: #1D1D1F;
	--vkws_landing--variant-card-title--color: #F1F1F1;
	--vkws_landing--variant-card-desc--color: #00D9ED;
	--vkws_landing--variant-card-button--bg-hover: #cccccc;

	/* Request */
	--vkws_landing--input--bg: rgba(237, 240, 243, 0.2);
	--vkws_landing--input--bg_disabled: rgba(255, 255, 255, 0.2);
	--vkws_landing--input--bg_focus: rgba(237, 240, 243, 0.2);
	--vkws_landing--input--bg_hover: rgba(237, 240, 243, 0.3);
	--vkws_landing--input--border: 1px solid #5C5C5C;
	--vkws_landing--input--border_focus: 1px solid #5C5C5C;
	--vkws_landing--input--border_hover: 1px solid #5C5C5C;
	--vkws_landing--input--required-star--color: #01EAFF;
	--vkws_landing--input--text-color: #FFF4F4;
	--vkws_landing--textarea--bg: rgba(237, 240, 243, 0.2);
	--vkws_landing--textarea--bg_focus: rgba(237, 240, 243, 0.2);
	--vkws_landing--textarea--bg_hover: rgba(237, 240, 243, 0.3);
	--vkws_landing--textarea--border: 1px solid #5C5C5C;
	--vkws_landing--textarea--border_focus: 1px solid #5C5C5C;
	--vkws_landing--textarea--border_hover: 1px solid #5C5C5C;
	--vkws_landing--textarea--text-color: #FFF4F4;

	--vkws_landing--checkbox-background-color-checked: #2688EB;
	--vkws_landing--checkbox-border-radius-checkbox: 4px;
	--vkws_landing--checkbox-border-color-idle: #99A2AD;
	--vkws_landing--checkbox-background-color-idle: #1D1D1F;
	--vkws_landing--checkbox-figure-color-checked: #1D1D1F;

	--vkws_landing--dialog--backdrop-bg: rgba(169, 169, 169, 0.15);
	--vkws_landing--dialog--bg: var(--vkws_landings--color--dark-grey);
	--vkws_landing--dialog--close-icon--color: #ffffff;
	--vkws_landing--dialog--description--color: #ffffff;
	--vkws_landing--dialog--title--color: #ffffff;
	--vkws_landing--footer--bg: var(--vkws_landings--color--black);
	--vkws_landing--footer_title--color: var(--vkws_landings--color--white);
	--vkws_landing--footer--border-top-color: #f9f9f9;
	--vkws_landing--footer--border-top-opacity: 0.1;
	--vkws_landing--footer--menu-text-color: #f9f9f9;

	--vkws_landing--faq_question--border-color: transparent;
	--vkws_landing--faq_quesion--color: var(--vkws_landings--color--white);
}
