.root-bg {
	background-color: var(--vkws_landings--bg_body);
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	padding-top: 32px;
	padding-bottom: 0;
}

.title {
	color: var(--vkws_landing--promo-title--color);
	font-size: 24px;
	font-weight: 500;
	line-height: 120%;
	margin: 0 0 20px;
}

.media {
	width: 100%;
	margin-bottom: 20px;
}

.video-wrapper {
	position: relative;
}

.video {
	display: block;
	width: 100%;
	height: auto;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 40px;
		padding-bottom: 0;
		width: 884px;
	}

	.title {
		font-size: 32px;
		max-width: 835px;
		margin-bottom: 60px;
	}

	.media {
		margin-bottom: 30px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 80px;
		padding-bottom: 0;
		width: 1240px;
	}

	.title {
		font-size: 54px;
		max-width: 1224px;
		margin-bottom: 83px;
	}

	.media {
		margin-bottom: 70px;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}
}
